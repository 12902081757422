<template>
    <div class="card">
        <div class="card-header pb-0">
        <h6>Data Transaksi</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div v-if="loading">
                <i class="fas fa-spin fa-sync-alt fa-2x m-3"></i>
            </div>
            <div v-else>
                <div class="row g-1">
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>Tanggal Awal</label>
                        <input
                            type="date"
                            class="form-control"
                            id="fdate"
                            v-model="fdate"
                        />
                    </div>
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>Tanggal Akhir</label>
                        <input
                            type="date"
                            class="form-control"
                            id="ldate"
                            v-model="ldate"
                        />
                    </div>
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>Kode Order</label>
                        <input
                            type="text"
                            class="form-control"
                            id="tid"
                            v-model="tid"
                        />
                    </div>
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>Kategori/Game</label>
                        <select v-model="selectedCategory" class="form-control">
                            <option v-for="category in categories" :key="category.kode" :value="category.kode" >
                                {{ category.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>Username/ ID Game</label>
                        <input
                            type="text"
                            class="form-control"
                            id="usergame"
                            v-model="usergame"
                            />
                    </div>
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>* Klik untuk cari</label>
                        <a @click="TrkData()" class="btn btn-primary w-100"><i class="fas fa-search"></i> Cari</a>
                    </div>  
                </div>
                
                <div class="table-responsive p-1 mt-1">
                    <table class="table align-items-center mb-0 table-striped table-hovered table-condensed table-bordered table-sm">
                        <thead>
                            <tr>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                No
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Aksi
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                Kode Order
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Reseller
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                Game/Kategori
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                Produk
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                Username/ID Game
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Harga
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Metode Bayar
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Total Biaya
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Status Transaksi
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Tanggal Transaksi
                            </th>
                            
                            </tr>
                        </thead>
                        <tbody v-if="loadtrkdata">
                            <tr>
                                <td colspan="11" align="center"><i class="fa fa-sync-alt fa-spin fa-2x"></i> Loading...</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(k, index) in this.trk" :key="index">
                                <td class="text-xs">{{index+1}}</td>
                                <td class="text-center m-0">
                                    <div class="btn-group btn-sm m-0">
                                    <a v-if="k.status!='SUCCESS'" @click="hapusData(k.tid)" class="btn btn-danger btn-sm px-3"><i class="fas fa-trash"></i></a>
                                    <a :href="'/trkdetail/'+k.tid" class="btn btn-info btn-sm px-3"><i class="fas fa-search"></i></a>
                                    </div>
                                </td>  
                                <td class="text-sm">{{k.tid}}</td>
                                <td class="text-sm">{{k.resname}}<br>{{k.level }} </td>
                                <td class="text-sm">{{k.kodecat}}</td>
                                <td class="text-sm">{{k.kodeproduk}}</td>
                                <td class="text-sm">{{k.usergame}}</td>
                                <td class="text-sm" align="right">{{formatPrice(k.harga)}}</td>
                                <td class="text-sm">{{k.bayarvia}}</td>
                                <td class="text-sm" align="right">{{formatPrice(k.totalbiaya)}}</td>
                                <td class="text-sm">
                                    <span v-if="k.status=='SUCCESS'" class="badge bg-success">
                                    {{k.status}}
                                    </span>
                                    <span v-else class="badge bg-danger">
                                        {{k.status}}
                                        </span>
                                </td>
                                <td class="text-sm">{{k.order_dibuat}}</td>
                                
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    //const apikey_dev='04fe3ca7d9d1255b71f7f4c8b051c232353f1ff41097649543059a61f87878f6';
    // import { createToaster } from "@meforma/vue-toaster";
    import axios from 'axios';
    
    // const toaster = createToaster({ /* options */ });
    
    export default {
        name: 'kategori',
        data(){
            return {
                loading: true,
                categories: [],
                trk:[],
                fdate: new Date().toISOString().substr(0, 10),
                ldate: new Date().toISOString().substr(0, 10),
                tid: '',
                selectedCategory: '',
                usergame: '',
                loadtrkdata: false,
                trkmodal:[],
            }
        },
        mounted(){
            this.getGamesCat();
            this.TrkData();
        },
        methods: {
            getGamesCat(){
                axios.get('https://tokogamingpedia.com/app/?active_category')
                    .then(response => {
                        this.categories = response.data;
                        // toaster.success('Loaded')
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading=false;
                    });
            },
            TrkData(){
                const fdate = this.fdate;
                const ldate = this.ldate;
                const tid = this.tid;
                const selectedCategory = this.selectedCategory;
                const usergame = this.usergame;
                this.loadtrkdata=true;
                
                axios.get(`https://tokogamingpedia.com/app/transaksi?trk_data&fdate=${fdate}&ldate=${ldate}&tid=${tid}&kodecat=${selectedCategory}&usergame=${usergame}`)
                 .then(response => {
                        this.trk = response.data;
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loadtrkdata=false;
                    });
            },
            formatPrice(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            detailTrk(tid){
                axios.get('https://tokogamingpedia.com/app/order?order_status&tid='+tid)
                .then(response => {
                    this.trkmodal.tid=tid;
                    this.trkmodal.code=response.data.code;
                    this.trkmodal.tid=response.data.data.tid;
                    this.trkmodal.produk=response.data.data.transactions[0].product_name;
                    this.trkmodal.voucher=response.data.data.transactions[0].voucher_code;
                    this.trkmodal.status=response.data.data.status;
                    console.log(this.trkmodal);
                })
                .catch(error => {
                    console.error(error)
                })
            }
            
        }
    }
    </script>