<template>
    <div class="row g-2">
        <div class="form-group col-lg-4 col-md-6">
            <label>Pilih Game</label>
            <select v-model="selectedCategory" class="form-control" @change="getProduk">
                <option v-for="category in categories" :key="category.kode" :value="category.kode" >
                    {{ category.nama }}
                </option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-6">
            <label>Pilih Produk</label>
            <select v-model="selectedProduk" class="form-control" @change="getHarga()">
                <option v-for="produk in produks" :key="produk.kodeproduk" :value="produk.kodeproduk" >
                    {{ produk.kodeproduk }} | {{ produk.nama }} ( Rp. {{ formatPrice(produk.hrg_jual) }})
                </option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-6">
            <label>Harga</label>
            <div align="right">
            <h4>Rp. {{formatPrice(this.prodtail.harga)}}</h4>
        </div>
            <input type="hidden" class="form-control" id="harga" v-model="prodtail.harga" required >
        </div>
        <div class="form-group col-lg-4 col-md-6">
            <label>Username/ID Game</label>
            <input type="text" class="form-control" id="usergame" v-model="usergame" required>
        </div>
        <div class="form-group col-lg-4 col-md-6">
            <label>Zone ID</label>
            <input type="text" class="form-control" id="zoneid" v-model="zoneid" required>
        </div>
        <div class="form-group col-lg-4 col-md-6">
            <label>Server</label>
            <select v-model="selectedServer" class="form-control" :disabled="servers.length === 0">
                <option v-for="server in servers" :key="server.name" :value="server.value" >
                    {{ server.value }}
                </option>
            </select>
        </div>
        <div class="col-lg-4 col-md-6">
            <label>* Klik untuk membuat order</label>
            <a @click.prevent="order()" class="btn btn-primary w-100" :class="{ 'disabled': loading_order }" v-show="!loading_order">
                <i class="fas fa-paper-plane"></i> Buat Order
                <span v-if="loading_order" class="fas fa-spin fa-sync-alt" role="status" aria-hidden="true"></span>
            </a>
        </div>
        <div class="col-lg-12">
            <div v-if="this.orderstatus=='SUCCESS'">
                <div class="alert alert-success" role="alert">
                    Order Berhasil!
                    <br>
                    <div class="list-group">
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col-4">
                                     Kode Order
                                </div>
                                <div class="col-8">
                                    {{ this.orderdetails.tid }}
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col-4">
                                     Nama Produk
                                </div>
                                <div class="col-8">
                                    {{ this.orderdetails.produk }}
                                </div>
                            </div>  
                        </div>
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col-4">
                                     Voucher
                                </div>
                                <div class="col-8">
                                    {{ this.orderdetails.voucher}}
                                </div>
                            </div>  
                        </div>
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col-4">
                                     Status
                                </div>
                                <div class="col-8">
                                    {{ this.orderdetails.status }}
                                </div>
                            </div>  
                        </div>
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col-4">
                                     Link Pembayaran
                                </div>
                                <div class="col-8">
                                    <a class="btn btn-primary btn-sm" target="_blank" :href="this.payment.invoice_url">Klik disini untuk bayar</a>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
    data() {
        return {
        categories: [],
        produks: [],
        prodtail:[],
        servers:[],
        selectedCategory: '',
        selectedProduk: '',
        selectedServer: '',
        usergame: '',
        zoneid:'',
        categoryCheckId: '',
        orderstatus: '',
        tid: '',
        orderdetails: [],
        loading_order: false,
        payment:[]
        };
    },
    mounted() {
        this.getGamesCat(),
        this.siAkun() 
    },
    methods: {
        siAkun(){
          const akun = localStorage.getItem("akun");
          this.akun=akun;
        },
        getGamesCat(){
            axios.get('https://tokogamingpedia.com/app/?active_category')
                .then(response => {
                    
                    this.categories = response.data;
                    this.categoryCheckId = response.data[0].check_id;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        formatPrice(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        getProduk() {
            if (this.selectedCategory) {
                axios.get(`https://tokogamingpedia.com/app/?produk&kodekat=${this.selectedCategory}`)
                .then(response => {
                    this.produks = response.data
                })
                .catch(error => {
                    console.error(error)
                })

                axios.get(`https://tokogamingpedia.com/app/?gen_server&cat=${this.selectedCategory}`)
                .then(serv => {
                    this.servers = serv.data
                    console.log(serv.data);
                })
                .catch(error => {
                    console.error(error)
                })
            }
        },
        getHarga() {
            if (this.selectedProduk) {
                axios.get(`https://tokogamingpedia.com/app/?getproduk=${this.selectedProduk}`)
                .then(response => {
                    this.prodtail.harga = response.data[0].hrg_jual
                    console.log(response.data[0].hrg_jual);
                })
                .catch(error => {
                    console.error(error)
                })
            }
        },
        order(){
            this.loading_order = true;
            const formData = new FormData();
                formData.append('kategori', this.selectedCategory);
                formData.append('produk', this.selectedProduk);
                formData.append('usergame', this.usergame);
                formData.append('server', this.selectedServer);
                formData.append('zoneid', this.zoneid);
                formData.append('harga', this.prodtail.harga);
                formData.append('reseller',this.akun);
                axios.post('https://tokogamingpedia.com/app/order?buat_order', formData)
                    .then(res => {
                        console.log(res.data);
                        if(res.data.code=='SUCCESS')
                        {
                            this.orderstatus = res.data.code;
                            this.tid = res.data.data.tid;
                            toaster.success(res.data.code,{
                                position: 'top-right',
                                timeout: 3000,
                            });
                            this.ordertid();    
                            this.pay();
                        }
                        else{
                            toaster.error(res.data.code,{
                                position: 'top-right',
                                timeout: 3000
                            });
                        }
                        
                        this.selectedCategory='';
                        this.selectedProduk='';
                        this.usergame='';
                        this.zoneid='';
                        this.selectedServer='';
                        this.prodtail.harga='';
                    })
                    .catch(err => {
                        console.log(err);
                        toaster.error(err,{
                            position: 'top-right',
                            timeout: 3000
                        });
                    })
                    .finally(() => {
                        this.loading_order = false;
                    });
        },
        ordertid(){
            axios.get('https://tokogamingpedia.com/app/order?order_status&tid='+this.tid)
                .then(response => {
                    this.orderdetails.code=response.data.code;
                    this.orderdetails.tid=response.data.data.tid;
                    this.orderdetails.produk=response.data.data.transactions[0].product_name;
                    this.orderdetails.voucher=response.data.data.transactions[0].voucher_code;
                    this.orderdetails.status=response.data.data.status;
                    console.log(this.orderdetails);
                })
                .catch(error => {
                    console.error(error)
                })
        },
        pay(){
            axios.get('https://tokogamingpedia.com/app/payment?bayar&tid='+this.tid)
                .then(respay => {
                    this.payment=respay.data;
                    console.log(this.payment);
                })
                .catch(error => {
                    console.error(error)
                })
        }
        
    }
}
</script>