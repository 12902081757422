<template>
    <div class="card">
        <div class="card-header pb-0">
        <h6>Data Pelanggan</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <button type="button" class="btn btn-sm btn-primary p-2 m-1" data-bs-toggle="modal" data-bs-target="#addPelangganModal">Tambah Pelanggan</button>
            <button @click="this.$parent.togglePelanggan()" class="btn btn-sm btn-dark p-2 m-1"><i class="fas fa-sync-alt"></i></button>
            <div class="modal fade" id="addPelangganModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Tambah Pelanggan</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="mb-3">
                                    <label for="nama" class="form-label">Nama</label>
                                    <input type="text" class="form-control" id="nama" v-model="gamer.nama" required>
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" class="form-control" id="email" v-model="gamer.email" required>
                                </div>
                                <div class="mb-3">
                                    <label for="hp" class="form-label">No. HP</label>
                                    <input type="text" class="form-control" id="hp" v-model="gamer.hp" required>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="this.$parent.togglePelanggan()" class="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                            <button type="submit" @click="addPelanggan()" form="addPelangganForm" class="btn btn-primary">Tambah</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading">
                <i class="fas fa-spin fa-sync-alt fa-2x m-3"></i>
            </div>
            <div v-else>
                <div class="table-responsive p-1">
                    <table class="table align-items-center mb-0 table-striped table-hovered table-condensed table-bordered">
                        <thead>
                            <tr>
                            <!-- <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                Kode
                            </th> -->
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                No
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                Nama
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                Email
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                No. HP
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Aksi
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(p, index) in this.pelanggan" :key="index">
                                <!-- <td>{{ k.code }}</td> -->
                                <td>{{ index+1}}</td>
                                <td>{{ p.nama}}</td>
                                <td class="text-center">{{ p.email }}</td>
                                <td class="text-center">{{ p.hp }}</td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <router-link :to="'editpelanggan/' + p.idgamer" class="btn btn-sm btn-primary p-2 m-1"><i class="fas fa-edit"></i> Edit</router-link>
                                        <a @click="pelangganhapus(p.idgamer)" class="btn btn-sm btn-danger p-2 m-1"><i class="fas fa-trash"></i> Hapus</a>    
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    //const apikey_dev='04fe3ca7d9d1255b71f7f4c8b051c232353f1ff41097649543059a61f87878f6';
    import axios from 'axios';
    import { createToaster } from "@meforma/vue-toaster";
    const toaster = createToaster({ /* options */ });
    
    export default 
    {
        name: 'pelanggan',
        
        data(){
            return {
                gamer:{
                    nama : '',
                    email : '',
                    hp : ''
                },
                loading: true
            }
        },
        mounted(){
            this.PelangganData();
        },
        methods: 
        {
            PelangganData()
            {    
                axios.get('https://tokogamingpedia.com/app/?pelanggan')
                .then(res => {
                    this.pelanggan = res.data;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            addPelanggan() 
            {
                const formData = new FormData();
                formData.append('email', this.gamer.email);
                formData.append('nama', this.gamer.nama);
                formData.append('hp', this.gamer.hp);
                axios.post('https://tokogamingpedia.com/app/?pelanggan_tambah', formData)
                    .then(res => {
                        //console.log(res.data);
                        if(res.data.kode=='1')
                        {
                            toaster.success(res.data.pesan,{
                                position: 'top-right',
                                timeout: 1000,
                                onTimeout: () => {
                                    this.$parent.togglePelanggan();
                                }
                            });

                            // this.$parent.showPelanggan=true;
                        }
                        else if(res.data.kode=='2')
                        {
                            toaster.warning(res.data.pesan,{
                                position: 'top-right',
                                timeout: 3000
                            });
                        }
                        else
                        {
                            toaster.error(res.data.pesan,{
                                position: 'top-right',
                                timeout: 3000
                            });
                        }
                        
                        // Clear form fields
                        this.gamer.nama = '';
                        this.gamer.email = '';
                        this.gamer.hp = '';
                        // Close modal
                        
                        // Refresh data
                        //this.PelangganData()
                    })
                    .catch(err => {
                        console.log(err);
                        toaster.error(err,{
                            position: 'top-right',
                            timeout: 3000
                        });
                    });
            },
            pelangganhapus(idgamer){
                axios.get('https://tokogamingpedia.com/app/?pelanggan_hapus&gamer='+idgamer)
                    .then(res => {
                        //console.log(res.data);
                        if(res.data.kode=='1')
                        {
                            toaster.success(res.data.pesan,{
                                position: 'top-right',
                                timeout: 1000,
                                onTimeout: () => {
                                    this.$parent.togglePelanggan();
                                }
                            });
                            //this.$parent.showPelanggan=true;
                            
                        }
                        else
                        {
                            toaster.error(res.data.pesan,{
                                position: 'top-right',
                                timeout: 3000
                            });
                        }
                        
                        
                        
                    })
                    .catch(err => {
                        console.log(err);
                        toaster.error(err,{
                            position: 'top-right',
                            timeout: 3000
                        });
                    });
            }
        },   
    }
    </script>