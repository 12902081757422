<template>
  <div class="py-4 container-fluid" >
    <div class="row">
      <div class="col-lg-12">
        <div class="p-2">
          <h4 class="text-white">Selamat datang, <b>{{ this.akun}}</b></h4>
        </div>
        <div class="row" style="height: 100vh;">
          <div class="col-lg-3 col-md-6 col-12">
            <div class="card mb-3">
              <div class="card-body p-3">
                <h6 class="text-capitalize text-muted mb-3">Saldo</h6>
                <div align="right">
                  <h4><b>Rp. {{ formatPrice(this.saldo) }}</b></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="card mb-3">
              <div class="card-body p-3">
                <h6 class="text-capitalize text-muted mb-3">Penjualan Bulan ini</h6>
                <div align="right">
                  <h4><b>Rp. {{ formatPrice(this.trkcur.curmon) }}</b></h4>
                </div>  
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="card mb-3">
              <div class="card-body p-3">
                <h6 class="text-capitalize text-muted mb-3">Penjualan Tahun ini</h6>
                <div align="right">
                  <h4><b>Rp. {{ formatPrice(this.trkcur.curyear) }}</b></h4>
                </div>  
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card card-body">
              <apexcharts width="100%" height="350" type="line" :options="chartOptions" :series="series"></apexcharts>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'Chart',
    data(){
        return {
            saldo:[],
            trkweek: {
              label: [],
              total: []
            },
            trkcur: {
              curmon: null,
              curyear: null
            },
            loading: true,
            chartOptions: {
              chart: {
                id: 'basic-bar',
                animations: 
                {
                    enabled: true,
                    easing: 'linear',
                    speed: 400,
                    animateGradually: {
                        enabled: true,
                        delay: 250
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                }
              },
              xaxis: {
                categories: []
              },
              dataLabels: {
                enabled: true,
                
              },
              legend:{
                show: true
              },
              title:{
                text: 'Penjualan 7 Hari Terakhir '
              }
            },
            series: [{
              name: 'Penjualan',
              data: []
            }]
        }
    },
    components: {
      apexcharts: VueApexCharts,
    },
  mounted(){
        this.Saldo();
        this.Trkcurmon();
        this.Trkweek();
        this.generateLast7Days();
        this.siAkun();
    },
    methods: {
        siAkun(){
          const akun = localStorage.getItem("nama");
          this.akun=akun;
        },
        Saldo(){    
            axios.get('https://tokogamingpedia.com/app/?balance')
            .then(res => {
                this.saldo = res.data.data.balance;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        Trkcurmon(){    
            axios.get('https://tokogamingpedia.com/app/?trk_cur')
            .then(res => {
                this.trkcur.curmon = res.data.current_month;
                this.trkcur.curyear = res.data.current_year;
                this.chartOptions.xaxis.categories = this.trkweek.label; 
                //console.log(res.data)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        generateLast7Days() {
          const today = new Date();
          const last7Days = [];

          for (let i = 0; i < 7; i++) {
            let pastDate = new Date(today);
            pastDate.setDate(today.getDate() - i);
            last7Days.unshift(this.formatDate(pastDate)); // Menambahkan tanggal ke awal array
          }

          this.chartOptions.xaxis.categories = last7Days; // Memasukkan tanggal ke dalam chartOptions
          this.loading = false;
        },
        formatDate(date) {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        },
        Trkweek(){    
            axios.get('https://tokogamingpedia.com/app/?trkweek')
            .then(res => {
                this.trkweek.label = res.data.label;
                this.trkweek.total = res.data.total;
                this.chartOptions.xaxis.categories = this.last7Days;
                this.series[0].data = this.trkweek.total;
                //console.log(this.chartOptions.xaxis.categories)
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
      }
}
</script>