<template>
  <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <h3 class="">Menu Master</h3>
            <div class="card">
              <div class="card-body p-2">
                <div class="row g-1 p-1">
                  <div class="col-6 col-md-2">
                    <button  @click="toggleKategori" class="btn btn-sm btn-primary w-100">Kategori
                      <span v-if="showKategori===true" class="fas fa-circle"></span>
                    </button>
                  </div>
              
                  <div class="col-6 col-md-2">
                    <button  @click="toggleProduk" class="btn btn-sm btn-primary w-100">Produk 
                      <span v-if="showProduk===true" class="fas fa-circle"></span>
                    </button>
                  </div>
              
                  <div class="col-6 col-md-2">
                    <button  @click="togglePelanggan" class="btn btn-sm btn-primary w-100">Pelanggan 
                      <span v-if="showPelanggan===true" class="fas fa-circle"></span>
                    </button>
                  </div>

                  <div class="col-6 col-md-2">
                    <button  @click="toggleReseller" class="btn btn-sm btn-primary w-100">Reseller 
                      <span v-if="showReseller===true" class="fas fa-circle"></span>
                    </button>
                  </div>
              
                  <div class="col-6 col-md-2">
                    <!-- <div v-if="loading">
                      <button class="btn btn-sm btn-light w-100" title="Sedang mendapatkan produk dan harga terbaru...">
                        <i class="fas fa-spin fa-sync-alt fa-2x"></i> 
                      </button>
                    </div> -->
                    <!-- <div v-else class="btn-group w-100">
                      <button class="btn btn-sm btn-info text-dark" title="Produk Baru">
                        {{ this.gp.produk_baru }}
                      </button>
                      <button class="btn btn-sm btn-warning" title="Harga Produk Diperbarui">
                        {{ this.gp.produk_update }}
                      </button>
                      <button class="btn btn-sm btn-dark text-white" title="Status Produk Diperbarui">
                        {{ this.gp.update_status }}
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <div class="card card-body p-2">
              
             
              <KategoriComponent v-if="showKategori"/>
              <PelangganComponent v-if="showPelanggan"/>
              <ProdukComponent v-if="showProduk"/>
              <PromoComponent v-if="showPromo"/>
              <ResellerComponent v-if="showReseller"/>
            </div>
          </div>
      </div>
    </div>
</template>
  
  <script>
  import KategoriComponent from './Kategori.vue'; // Import the component
  import PelangganComponent from './Pelanggan.vue';
  import ProdukComponent from './Produk.vue';
  import PromoComponent from './Promo.vue';
  import ResellerComponent from './Reseller.vue';
  import axios from 'axios';
  import { createToaster } from "@meforma/vue-toaster";
    const toaster = createToaster({ /* options */ });

  export default {
  name: "App",
  components: {
    KategoriComponent,
    PelangganComponent,
    ProdukComponent,
    PromoComponent,
    ResellerComponent,
  },
  data() {
    return {
      showKategori: true,
      showProduk: false,
      showPelanggan: false,
      showPromo: false,
      showReseller: false,
      loading: true
    };
  },
  mounted() {
    //this.GenProduct();
  },
  methods: {
    GenProduct () {
      axios.get('https://tokogamingpedia.com/app/gendb?gen_products')
      .then(res => {
                this.gp = res.data;
                console.log(this.gp);
            })
            .catch(err => {
                toaster.error(err,{
                            position: 'top-right',
                            timeout: 3000
              });
            })
            .finally(() => {
                this.loading = false;
            });
    },
    toggleKategori () {
      this.showKategori = !this.showKategori;
      this.showProduk = false;
      this.showPelanggan = false;
      this.showPromo = false;
      this.showReseller = false;
    },
    togglePelanggan () { 
      this.showPelanggan = !this.showPelanggan;
      this.showProduk = false;
      this.showKategori = false;
      this.showPromo = false;
      this.showReseller = false;
    },
    toggleProduk () {
      this.showProduk = !this.showProduk;
      this.showKategori = false;
      this.showPelanggan = false;
      this.showPromo = false;
      this.showReseller = false;
    },
    togglePromo () {
      this.showPromo =!this.showPromo;
      this.showProduk = false;
      this.showPelanggan = false;
      this.showKategori = false;
      this.showReseller = false;
    },
    toggleReseller () {
      this.showReseller = !this.showReseller;
      this.showProduk = false;
      this.showPelanggan = false;
      this.showKategori = false;
      this.showPromo = false;
    }
  }
};
  </script>