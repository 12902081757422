<template>
    <tbody v-if="loading">
        <tr>
            <td colspan="11" align="center"><i class="fa fa-sync-alt fa-spin fa-2x"></i> Loading...</td>
        </tr>
    </tbody>
    <tbody v-else>    
        <tr>
            <td colspan="12">
                <div class="form-group p-2 col-lg-6 col-md-4">
                    <input
                        type="text"
                        class="form-control"
                        id="cariprod"
                        placeholder="Cari Produk"
                        v-model="cariprod"
                        @keyup="ProdukCategory()"
                    />
                </div>
            </td>
        </tr>
        <tr v-for="(p, index) in this.produk" :key="index">
            <td align="center">{{index+1}}</td>
            <td>
                <router-link :to="'../../editproduk/' + p.idproduk + '/'+p.namakat" class="btn btn-sm btn-info">Edit</router-link>
            </td>
            <td><small>{{p.kodeproduk}} <br>{{p.namakat}}<br> Prv: {{p.provider}}  | Cty: {{ p.country_code }} </small></td>
            <td align="center">{{p.nama}}</td>
            <td align="right">
                <span v-if="p.hrg_sebelum > p.hrg_modal">
                    <small class="text-success">({{ formatPrice(p.hrg_sebelum) }}) </small><br>
                    <i class="fa fa-caret-down text-success fa-2x"></i>
                </span> 
                <span v-else-if="p.hrg_sebelum < p.hrg_modal">
                    <small class="text-danger">({{ formatPrice(p.hrg_sebelum) }}) </small><br>
                    <i class="fa fa-caret-up text-danger fa-2x"></i>
                    </span>
                {{formatPrice(p.hrg_modal)}}
            </td>
            <td align="right" style="font-weight:700"><b>{{formatPrice(p.hrg_jual)}} 
                </b> <small v-if="p.hrg_jual != ''"><br>({{formatPercent((p.hrg_jual-p.hrg_modal)/p.hrg_modal*100) }} %)</small></td>
            <td align="right" style="font-weight:700"><b>{{formatPrice(p.hrg_reseller1)}}</b>
                <small v-if="p.hrg_reseller1 != ''"><br>({{formatPercent((p.hrg_reseller1-p.hrg_modal)/p.hrg_modal*100) }} %)</small>    
            </td>
            <td align="right" style="font-weight:700"><b>{{formatPrice(p.hrg_reseller2)}}</b>
                <small v-if="p.hrg_reseller2 != ''"><br>({{formatPercent((p.hrg_reseller2-p.hrg_modal)/p.hrg_modal*100) }} %)</small>    </td>
            <td align="right" style="font-weight:700"><b>{{formatPrice(p.hrg_reseller3)}}</b>
                <small v-if="p.hrg_reseller3 != ''"><br>({{formatPercent((p.hrg_reseller3-p.hrg_modal)/p.hrg_modal*100) }} %)</small>    </td>
            <td align="right" style="font-weight:700"><b>{{formatPrice(p.hrg_reseller4)}}</b>
                <small v-if="p.hrg_reseller4 != ''"><br>({{formatPercent((p.hrg_reseller4-p.hrg_modal)/p.hrg_modal*100) }} %)</small>    </td>
                <td align="right" style="font-weight:700"><b>{{formatPrice(p.hrg_g2g)}}</b>
                    <small v-if="p.hrg_g2g != ''"><br>({{formatPercent((p.hrg_g2g-p.hrg_modal)/p.hrg_modal*100) }} %)</small>    </td>
            <td align="center" valign="middle" style="font-weight: 800;">{{p.status}}</td>
            
        </tr>   
    </tbody>
</template>
<script>
import axios from 'axios';
export default {
    name: 'produk',
    data(){
        return {
            produk:[],
            loading: true,
            cariprod:''
        }
    },
    mounted(){
        this.ProdukCategory();
    },
    methods: {
        refresh() {
            // Your logic to refresh or fetch data
            this.ProdukCategory();
        },
        ProdukCategory(){    
            const codeCategory=this.$route.params.codeCategory;
            if (!codeCategory) {
                axios.get('https://tokogamingpedia.com/app/?produk&kodekat=&cariprod='+this.cariprod)
                .then(res => {
                    this.produk = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
                ;
            }
            else
            {
                axios.get(`https://tokogamingpedia.com/app/?produk&kodekat=${codeCategory}&cariprod=${this.cariprod}`)
                .then(res => {
                    this.produk = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
                ;
            }
            
        },
        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatPercent(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    }
}
</script>