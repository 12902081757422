<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-lg-7">
                    <h5 class="card-title">Produk <b>{{ this.$route.params.nameCategory }}</b></h5>
                </div>
                <div class="col-lg-5" align="right">
                    <router-link :to="'/master'" class="btn btn-dark btn-sm mx-1"><i class="fas fa-caret-left"></i> Kembali</router-link>
                    <button class="btn btn-info text-dark btn-sm mx-1" data-bs-toggle="modal" data-bs-target="#editharga"><i class="fas fa-cog"></i> Edit Harga Massal</button>
                </div>  

                <!-- Modal Add Harga Massal -->
                <div class="modal fade" id="editharga" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"><small>Edit Harga Massal Produk</small><br> <b>{{ this.$route.params.nameCategory }}</b></h5>
                                <button type="button" class="btn btn-dark btn-sm" data-bs-dismiss="modal" aria-label="Close">
                                    <span class="fas fa-times"></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="updateHargaMassal">
                                    <div class="form-group">
                                        <label>Harga Jual (%)</label>
                                        <input type="number" step="0.1" class="form-control" id="edit_jual" v-model="edit_jual">
                                    </div>
                                    <div class="form-group">
                                        <label>Harga Reseller Epic (%)</label>
                                        <input type="number" step="0.1" class="form-control" id="edit_reseller1" v-model="edit_reseller1">
                                    </div>
                                    
                                    <div class="form-group">
                                        <label>Harga Reseller Legendary (%)</label>
                                        <input type="number" step="0.1" class="form-control" id="edit_reseller2" v-model="edit_reseller2">
                                    </div>
                                    
                                    <div class="form-group">
                                        <label>Harga Reseller Mythic (%)</label>
                                        <input type="number" step="0.1" class="form-control" id="edit_reseller3" v-model="edit_reseller3">
                                    </div>
                                    
                                    <div class="form-group">
                                        <label>Harga Reseller Glorious Mythic (%)</label>
                                        <input type="number" step="0.1" class="form-control" id="edit_reseller4" v-model="edit_reseller4">
                                    </div>

                                    <div class="form-group">
                                        <label>Harga G2G (%)</label>
                                        <input type="number" step="0.1" class="form-control" id="edit_g2g" v-model="edit_g2g">
                                    </div>
                                    
                                    <div class="form-group">
                                        <div v-if="loading==false">
                                            <button type="submit" class="btn btn-primary btn-sm mt-3 w-50"><i class="fas fa-save"></i> Simpan</button>
                                        </div>
                                        <div v-else>
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>                                  

            </div>
        </div>
        <div class="card-body p-3">
            
            <div class="table-responsive">
                <table class="table table-bordered table-striped table-hovered table-condensed" style="font-size: 10pt;">
                    <thead>
                        <th>No.</th>
                        <th width="">Aksi</th>
                        <th>Kode Produk</th>
                        <th>Nama Produk</th>
                        <th>Harga Modal</th>
                        <th>Harga Jual</th>
                        <th>Harga Reseller <br>Epic</th>
                        <th>Harga Reseller <br>Legendary</th>
                        <th>Harga Reseller <br>Mythic</th>
                        <th>Harga Reseller <br>Glorious Mythic</th>
                        <th>Harga G2G</th>
                        <th>Status</th>
                    </thead>
                    <ProdukData ref="produkData"/>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";
  
import ProdukData from './ProdukData.vue';
const toaster = createToaster({ /* options */ });
export default{
    name: 'Produk',
    components: {
        ProdukData
    },
    data() {
        return{
            edit_jual : '',
            edit_reseller1 : '',
            edit_reseller2 : '',
            edit_reseller3 : '',
            edit_reseller4 : '',
            edit_g2g : '',
            loading: false
        }
    },
    mounted(){
        this.get_config_harga();
    },  
    methods: {
        
        updateHargaMassal(){
            this.loading = true;
            const fcogharga = new FormData();
                fcogharga.append('kodecat', this.$route.params.codeCategory);
                fcogharga.append('hrg_jual',this.edit_jual);
                fcogharga.append('hrg_reseller1', this.edit_reseller1);
                fcogharga.append('hrg_reseller2', this.edit_reseller2);
                fcogharga.append('hrg_reseller3', this.edit_reseller3);
                fcogharga.append('hrg_reseller4', this.edit_reseller4);
                fcogharga.append('hrg_g2g', this.edit_g2g);
            axios.post('https://tokogamingpedia.com/app/?cogharga', fcogharga)
           .then(response => {
                //console.log(this.edit_jual);
                toaster.success(response.data,{
                    position: 'top-right',
                    duration: 2000
                });
                this.loading = false;
                // Call the refresh method on the ProdukData component
                if (this.$refs.produkData) {
                        this.$refs.produkData.refresh();
                    }
            })
            .catch(err => {
                    console.log(err);
                    this.loading = false;
            });
        },
        get_config_harga()
        {
            axios.get('https://tokogamingpedia.com/app/?curcogharga&kodecat=' + this.$route.params.codeCategory)
           .then(response => {
                this.edit_jual = response.data.hrg_jual,
                this.edit_reseller1 = response.data.hrg_reseller1,
                this.edit_reseller2 = response.data.hrg_reseller2,
                this.edit_reseller3 = response.data.hrg_reseller3,
                this.edit_reseller4 = response.data.hrg_reseller4,
                this.edit_g2g = response.data.hrg_g2g
                console.log(response)
           })
           .catch(err => {
                console.log(err);
           });
        }
    }
}
</script>