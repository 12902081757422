<template>
    <div>
        Promo
    </div>
</template>

<script>
export default{
    
}
</script>