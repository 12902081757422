<template>
    <div class="py-4 container-fluid">
        <div class="row">
          <div class="col-12">
            <h3 class="">Menu Master</h3>
              <div class="card">
                <div class="card-body p-2">
                  <div class="row g-1 mt-1">
                    <div class="col-6 col-md-2">
                      <button  @click="toggleBuatTransaksi" class="btn btn-sm btn-dark w-100">Buat Transaksi 
                        <span v-if="showBuatTransaksi===true" class="fas fa-circle"></span>
                      </button>
                    </div>
                    <div class="col-6 col-md-2">
                      <button  @click="toggleTransaksiKeluar" class="btn btn-sm btn-primary w-100">Transaksi
                        <span v-if="showTransaksiKeluar===true" class="fas fa-circle"></span>
                      </button>
                    </div>
                    <div class="col-6 col-md-2">
                      <button  @click="toggleTransaksiG2G" class="btn btn-sm btn-primary w-100">Transaksi G2G
                        <span v-if="showTransaksiG2G===true" class="fas fa-circle"></span>
                      </button>
                    </div>
                
                    <div class="col-6 col-md-2">
                      <button  @click="toggleLaporan" class="btn btn-sm btn-primary w-100">Laporan
                        <span v-if="showLaporan===true" class="fas fa-circle"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="card card-body p-2">
                <BuatTransaksiComp v-if="showBuatTransaksi"/>
                <TransaksiKeluarComponent v-if="showTransaksiKeluar"/>
                <LaporanComponent v-if="showLaporan"/>
                <TransaksiG2G v-if="showTransaksiG2G"/>
              </div>
            </div>
        </div>
      </div>
  </template>
    
    <script>
    import TransaksiKeluarComponent from './TransaksiKeluar.vue'; // Import the component
    import LaporanComponent from './Laporan.vue';
    import BuatTransaksiComp from './BuatTransaksi.vue'; // Import the component
    import TransaksiG2G from './TransaksiG2G.vue'; // Import the component
    export default {
    name: "App",
    components: {
      TransaksiKeluarComponent,
      LaporanComponent,
      BuatTransaksiComp,
      TransaksiG2G
    },
    data() {
      return {
        showTransaksiKeluar: true,
        showLaporan: false,
        showBuatTransaksi: false,
        showTransaksiG2G: false
      };
    },
    methods: {
      toggleTransaksiKeluar () {
        this.showTransaksiKeluar = !this.showTransaksiKeluar;
        this.showLaporan = false;
        this.showBuatTransaksi = false;
        this.showTransaksiG2G = false;
      },
      toggleLaporan () {
        this.showLaporan = !this.showLaporan;
        this.showTransaksiKeluar = false;
        this.showBuatTransaksi = false;
        this.showTransaksiG2G = false;
      },
      toggleBuatTransaksi () {
        this.showBuatTransaksi = !this.showBuatTransaksi;
        this.showTransaksiKeluar = false;
        this.showLaporan = false;
        this.showTransaksiG2G = false;
      },
      toggleTransaksiG2G () {
        this.showTransaksiG2G = !this.showTransaksiG2G;
        this.showTransaksiKeluar = false;
        this.showLaporan = false;
        this.showBuatTransaksi = false;
      }
    }
  };
    </script>