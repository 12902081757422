<template>
    <div class="card">
        <div class="card-header pb-0">
        <h6>Data Resseller</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <button type="button" class="btn btn-sm btn-primary p-2 m-1" data-bs-toggle="modal" data-bs-target="#addRessellerModal">Tambah Resseller</button>
            <div class="modal fade" id="addRessellerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Tambah Resseller</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="mb-3">
                                    <label for="nama" class="form-label">Nama</label>
                                    <input type="text" class="form-control" id="nama" v-model="reseller.nama" required>
                                </div>
                                <div class="mb-3">
                                    <label for="username" class="form-label">Username</label>
                                    <input type="text" class="form-control" id="username" v-model="reseller.username" required>
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" class="form-control" id="email" v-model="reseller.email" required>
                                </div>
                                <div class="mb-3">
                                    <label for="hp" class="form-label">No. HP</label>
                                    <input type="text" class="form-control" id="hp" v-model="reseller.hp" required>
                                </div>
                                <div class="mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <input type="password" class="form-control" id="password" v-model="reseller.password" required>
                                </div>
                                <div class="mb-3">
                                    <label for="password" class="form-label">Level Reseller</label>
                                    <select class="form-control" name="level" id="level" v-model="reseller.level">
                                        <option value="1">Epic</option>
                                        <option value="2">Legendary</option>
                                        <option value="3">Mythic</option>
                                        <option value="4">Glorious Mythic</option>
                                    </select>
                                    
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="RessellerData" class="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
                            <button type="submit" @click="addResseller" form="addRessellerForm" class="btn btn-primary">Tambah</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading">
                <i class="fas fa-spin fa-sync-alt fa-2x m-3"></i>
            </div>
            <div v-else>
                <div class="table-responsive p-1">
                    <table class="table align-items-center mb-0 table-striped table-hovered table-condensed table-bordered">
                        <thead>
                            <tr>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                No
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                Nama
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                Username
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                Email
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                No. HP
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                Level
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Aksi
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(p, index) in this.resell" :key="index">
                                <!-- <td>{{ k.code }}</td> -->
                                <td class="text-sm font-weight-bolder  ">{{ index+1}}</td>
                                <td class="text-sm font-weight-bolder  ">{{ p.nama}}</td>
                                <td class="text-sm font-weight-bolder  text-center">{{ p.username }}</td>
                                <td class="text-sm font-weight-bolder  text-center">{{ p.email }}</td>
                                <td class="text-sm font-weight-bolder  text-center">{{ p.hp }}</td>
                                <td class="text-sm font-weight-bolder  text-center">{{ p.level }}</td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <router-link :to="'editResseller/' + p.idreseller" class="btn btn-sm btn-primary p-2 m-1"><i class="fas fa-edit"></i> Edit</router-link>
                                        <a @click="Ressellerhapus(p.idreseller)" class="btn btn-sm btn-danger p-2 m-1"><i class="fas fa-trash"></i> Hapus</a>    
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    //const apikey_dev='04fe3ca7d9d1255b71f7f4c8b051c232353f1ff41097649543059a61f87878f6';
    import axios from 'axios';
    import { createToaster } from "@meforma/vue-toaster";
    const toaster = createToaster({ /* options */ });
    
    export default 
    {
        name: 'Resseller',
        
        data(){
            return {
                reseller:{
                    nama : '',
                    username:'',
                    email : '',
                    hp : '',
                    password : '',
                    level : '1',
                },
                loading: true
            }
        },
        mounted(){
            this.RessellerData();
        },
        methods: 
        {
            RessellerData() {
            axios.get('https://tokogamingpedia.com/app/reseller?data_reseller')
                .then(res => {
                // Memasukkan data resellers dari respon ke dalam array resell
                this.resell = res.data.resellers;
                //console.log(res); // Menampilkan data reseller di console
                })
                .catch(err => {
                console.log(err); // Menampilkan error jika ada
                })
                .finally(() => {
                this.loading = false; // Menonaktifkan status loading
                });
            },
            addResseller(callback) {
                const formData = new FormData();
                formData.append('username', this.reseller.username);
                formData.append('email', this.reseller.email);
                formData.append('nama', this.reseller.nama);
                formData.append('hp', this.reseller.hp);
                formData.append('password', this.reseller.password);
                formData.append('level', this.reseller.level);
                
                axios.post('https://tokogamingpedia.com/app/reseller?tambah_reseller', formData)
                    .then(res => {
                        console.log(res.data);

                        if(res.data.kode == '1') {
                            toaster.success(res.data.pesan, {
                                position: 'top-right',
                                timeout: 1000
                            });
                            
                            // Panggil callback jika ada
                            if (typeof callback === 'function') {
                                callback(); // Memanggil callback setelah addResseller sukses
                            }
                        } else if (res.data.kode == '0') {
                            toaster.warning(res.data.pesan, {
                                position: 'top-right',
                                timeout: 3000
                            });
                        } else {
                            toaster.error(res.data.pesan, {
                                position: 'top-right',
                                timeout: 3000
                            });
                        }

                        // Clear form fields
                        this.reseller.username = '';
                        this.reseller.password = '';
                        this.reseller.nama = '';
                        this.reseller.email = '';
                        this.reseller.hp = '';
                        this.reseller.level = '1';
                    })
                    .catch(err => {
                        console.log(err);
                        toaster.error(err, {
                            position: 'top-right',
                            timeout: 3000
                        });
                    });
            },

            // Cara memanggil addResseller dengan callback untuk memanggil RessellerData

            Ressellerhapus(idreseller){
                toaster.info('Sedang proses...',{
                                position: 'top-right',
                                timeout: 3000
                            });
                axios.get('https://tokogamingpedia.com/app/reseller?hapus_reseller&reseller='+idreseller)
                    .then(res => {
                        //console.log(res.data);
                        if(res.data.kode=='1')
                        {
                            toaster.success(res.data.pesan,{
                                position: 'top-right',
                                timeout: 1000,
                                onTimeout: () => {
                                    this.toggleResseller();
                                }
                            });
                            //this.showResseller=true;
                            
                        } 
                        else
                        {
                            toaster.error(res.data.pesan,{
                                position: 'top-right',
                                timeout: 3000
                            });
                        }
                        
                        
                        
                    })
                    .catch(err => {
                        console.log(err);
                        toaster.error(err,{
                            position: 'top-right',
                            timeout: 3000
                        });
                    });
            }
        },   
    }
    </script>