<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-lg-7">
                    <h5 class="card-title">Produk <b>{{ this.$route.params.nameCategory }}</b></h5>
                </div>
                <div class="col-lg-5" align="right">
                    <router-link :to="'/reseller/master'" class="btn btn-dark btn-sm mx-1"><i class="fas fa-caret-left"></i> Kembali</router-link>
                    <button class="btn btn-info text-dark btn-sm mx-1" data-bs-toggle="modal" data-bs-target="#editharga"><i class="fas fa-cog"></i> Edit Harga Massal</button>
                </div>  

                <!-- Modal Add Harga Massal -->
                <div class="modal fade" id="editharga" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"><small>Edit Harga Massal Produk</small><br> <b>{{ this.$route.params.nameCategory }}</b></h5>
                                <button type="button" class="btn btn-dark btn-sm" data-bs-dismiss="modal" aria-label="Close">
                                    <span class="fas fa-times"></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="updateHargaMassal">
                                    <div class="form-group">
                                        <label>Harga Jual (%)</label>
                                        <input type="number" step="0.1" class="form-control" id="edit_jual" v-model="edit_jual">
                                    </div>
                                    
                                    
                                    <div class="form-group">
                                        <div v-if="loading==false">
                                            <button type="submit" class="btn btn-primary btn-sm mt-3 w-50"><i class="fas fa-save"></i> Simpan</button>
                                        </div>
                                        <div v-else>
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>                                  

            </div>
        </div>
        <div class="card-body p-3">
            
            <div class="table-responsive">
                <table class="table table-bordered table-striped table-hovered table-condensed" style="font-size: 10pt;">
                    <thead>
                        <th>No.</th>
                        <th width="">Aksi</th>
                        <th>Kode Produk</th>
                        <th>Nama Produk</th>
                        <th>Harga Modal</th>
                        <th>Harga Jual</th>
                        <th>Status</th>
                        <th>Ketersediaan</th>
                    </thead>
                    <ProdukData ref="produkData"/>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";
  
import ProdukData from './ProdukDataReseller.vue';
const toaster = createToaster({ /* options */ });
export default{
    name: 'Produk',
    components: {
        ProdukData
    },
    data() {
        return{
            edit_jual : '',
            loading: false
        }
    },
    mounted(){
        this.siAkun();
        this.get_config_harga();
    },  
    methods: {
        siAkun(){
          const akun = localStorage.getItem("nama");
          const email = localStorage.getItem("akun");
          const level = localStorage.getItem("level");
          this.level = level;
          this.akun=akun;
          this.email=email;
        },
        updateHargaMassal(){
            this.loading = true;
            const fcogharga = new FormData();
                fcogharga.append('kodecat', this.$route.params.codeCategory);
                fcogharga.append('hrg_jual',this.edit_jual);
                fcogharga.append('email',this.email);
            axios.post('https://tokogamingpedia.com/app/?cogharga', fcogharga)
           .then(response => {
                //console.log(this.edit_jual);
                toaster.success(response.data,{
                    position: 'top-right',
                    duration: 2000
                });
                this.loading = false;
                // Call the refresh method on the ProdukData component
                if (this.$refs.produkData) {
                        this.$refs.produkData.refresh();
                    }
            })
            .catch(err => {
                    console.log(err);
                    this.loading = false;
            });
        },
        get_config_harga()
        {
            axios.get('https://tokogamingpedia.com/app/?curcogharga&kodecat=' + this.$route.params.codeCategory)
           .then(response => {
                this.edit_jual = response.data.hrg_jual,
                console.log(response)
           })
           .catch(err => {
                console.log(err);
           });
        }
    }
}
</script>