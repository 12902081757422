<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { useRouter } from "vue-router";
import { onBeforeMount, onBeforeUnmount } from 'vue'; 
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { createToaster } from "@meforma/vue-toaster";

// Membuat instance toaster
const toaster = createToaster({ /* options */ });

// State Vuex
const store = useStore();
const router = useRouter();

// Mengambil elemen body
const body = document.getElementsByTagName("body")[0];

// Data form untuk email dan password
const email = ref("");
const password = ref("");
const errorMessage = ref("");

// Fungsi untuk login
const signIn = async () => {
  try {
    const formsignin = new FormData();
    formsignin.append("email", email.value);
    formsignin.append("password", password.value);

    const res = await axios.post('https://tokogamingpedia.com/app/login', formsignin);

    if (res.data.pesan == '1') {
      toaster.success('Login berhasil. Anda akan dialihkan...', {
        position: 'top-right',
        timeout: 3000,
      });
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('akun', res.data.akun);
      localStorage.setItem('nama', res.data.nama);
      // Redirect ke halaman dashboard
      console.log(res.data)
      if(res.data.level=='99')
      {
        localStorage.setItem('level', 'admin');
          router.push({ name: "Dashboard" });
      }
      else
      {
        localStorage.setItem('level', 'reseller');
          router.push({ name: "ResellerDashboard" });
      }
    } else if(res.data.pesan == '2'){
      toaster.error('Login gagal! Akun belum aktif', {
        position: 'top-right',
        timeout: 3000,
      });
    } else if(res.data.pesan == '3'){
      toaster.error('Login gagal! Email atau password salah', {
        position: 'top-right',
        timeout: 3000,
      });
    }
  } catch (err) {
    toaster.error(err.message || 'Terjadi kesalahan saat login', {
      position: 'top-right',
      timeout: 3000,
    });
  } finally {
    password.value = "";
  }
};

// Sebelum komponen mount
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});

// Sebelum komponen di-unmount
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12"></div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="signIn" role="form">
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        v-model="email"
                        size="lg"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        v-model="password"
                        size="lg"
                      />
                    </div>
                    <div class="text-center">
                      <ArgonButton type="submit">Login</ArgonButton>
                    </div>
                    <p v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</p>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>


