<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

// Komponen yang diimpor
import SidenavItem from "./SidenavItem.vue";
//import SidenavCard from "./SidenavCard.vue";

// Mengambil store dari Vuex
const store = useStore();
const isRTL = computed(() => store.state.isRTL);

// Mengambil level pengguna dari localStorage
const userLevel = localStorage.getItem('level');

// Fungsi untuk mendapatkan route saat ini
const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

// Computed property untuk menentukan menu berdasarkan level
const menuItems = computed(() => {
  if (userLevel === 'reseller') {
    return [
      { to: '/reseller/dashboard', text: 'Dashboard', icon: 'ni ni-tv-2 text-primary text-sm opacity-10' },
      { to: '/reseller/master', text: 'Master', icon: 'ni ni-box-2 text-success text-sm opacity-10' },
      { to: '/reseller/transaksi', text: 'Transaksi', icon: 'ni ni-cart text-warning text-sm opacity-10' }
    ];
  } else if (userLevel === 'admin') {
    return [
      { to: '/', text: 'Dashboard', icon: 'ni ni-tv-2 text-primary text-sm opacity-10' },
      { to: '/master', text: 'Master', icon: 'ni ni-box-2 text-success text-sm opacity-10' },
      { to: '/transaksi', text: 'Transaksi', icon: 'ni ni-chart-bar-32 text-danger text-sm opacity-10' }
    ];
  } else {
    return [
      { to: '/user/dashboard', text: 'Dashboard', icon: 'ni ni-tv-2 text-primary text-sm opacity-10' },
      { to: '/user/profile', text: 'Profil', icon: 'ni ni-single-02 text-info text-sm opacity-10' }
    ];
  }
});
</script>

<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li
        class="nav-item"
        v-for="(item, index) in menuItems"
        :key="index"
      >
        <sidenav-item
          :to="item.to"
          :class="getRoute() === item.to.split('/')[1] ? 'active' : ''"
          :navText="isRTL ? 'ترجمة' : item.text"
        >
          <template v-slot:icon>
            <i :class="item.icon"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
