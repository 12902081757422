<template>
    <div>
      <!-- Tampilan untuk notifikasi atau informasi lain -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'Notifications',
  
    mounted() {
      // Buat koneksi ke server WebSocket
      const socket = new WebSocket('ws://localhost:3001');
  
      // Dapatkan pesan dari WebSocket server dan tangani notifikasi
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.event_type === 'order.created') {
          this.showNotification(data);
        }
      };
    },
  
    methods: {
      showNotification(data) {
        // Menggunakan toastr untuk menampilkan notifikasi
        this.$toastr.success(`New order received: ${data.payload.order_id}`);
      }
    }
  };
  </script>
  