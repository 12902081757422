<template>
<div class="card">
    <div class="card-header pb-0">
    <h6>Data Kategori</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
        <div v-if="loading">
            <i class="fas fa-spin fa-sync-alt fa-2x m-3"></i>
        </div>
        <div v-else>
            <div class="form-group p-2 col-lg-6 col-md-4">
                <input
                    type="text"
                    class="form-control"
                    id="carikat"
                    placeholder="Cari Kategori/Game"
                    v-model="carikat"
                    @keyup="KategoriData()"
                />
            </div>
            <div class="table-responsive p-1">
                <table class="table align-items-center mb-0 table-striped table-hovered table-condensed table-bordered">
                    <thead>
                        <tr>
                        <!-- <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                            Kode
                        </th> -->
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                            No
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                            Nama
                        </th>
                        <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                            Varian
                        </th>
                        <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                            Cek ID/Username
                        </th>
                        <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                            Kode Negara
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Status
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Aksi
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(k, index) in this.kategori" :key="index">
                            <!-- <td>{{ k.code }}</td> -->
                            <td>{{ index+1}}</td>
                            <td>{{ k.nama}}</td>
                            <td class="text-center">{{ k.varian }}</td>
                            <td class="text-center">{{ k.check_id }}</td>
                            <td>{{ k.country_code }}</td>
                            <td>
                                <div v-if="k.status==''||k.status=='nonaktif'">
                                    <span class="badge bg-dark text-xxs">Nonaktif</span>
                                </div>
                                <div v-else>
                                    <span class="badge bg-light text-success text-xxs">Aktif</span>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="btn-group">
                                    
                                    <div v-if="k.status==''||k.status=='nonaktif'">
                                        <router-link :to="'produk/' + k.kode + '/'+ k.nama" class="btn btn-sm btn-primary p-2 m-1"><i class="fas fa-box"></i> Produk</router-link>                            
                                        <a @click="statusKategori(k.kode,'aktif')" class="btn btn-sm btn-outline-success p-2 m-1"><i class="fas fa-check-circle"></i> Aktifkan</a>
                                    </div>
                                    <div v-else>
                                        <router-link :to="'produk/' + k.kode + '/'+ k.nama" class="btn btn-sm btn-primary p-2 m-1"><i class="fas fa-box"></i> Produk</router-link>                            
                                        <a @click="statusKategori(k.kode,'nonaktif')" class="btn btn-sm btn-outline-danger p-2 m-1"><i class="fas fa-times-circle"></i> Nonaktifkan</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
</div>
</template>
<script>
//const apikey_dev='04fe3ca7d9d1255b71f7f4c8b051c232353f1ff41097649543059a61f87878f6';
import { createToaster } from "@meforma/vue-toaster";
import axios from 'axios';

const toaster = createToaster({ /* options */ });

export default {
    name: 'kategori',
    data(){
        return {
            kategori:[],
            loading: true,
            carikat: ''
        }
    },
    mounted(){
        this.KategoriData();
    },
    methods: {
        KategoriData(){    
            axios.get('https://tokogamingpedia.com/app/?category&carikat='+this.carikat)
            .then(res => {
                this.kategori = res.data;
                //console.log(this.kategori);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        statusKategori(kode,statusto) {
            axios.get('https://tokogamingpedia.com/app/?aktifkan-kategori&kode='+kode+'&statusto='+statusto)
            .then(kat => {
                console.log(kat.data);
                if(kat.data==1)
                {
                    toaster.success(`Status kategori berhasil di`+statusto+`kan`,{
                        position: 'top-right',
                        timeout: 3000
                    });
                    this.KategoriData();
                }    
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}
</script>