<template>
    <div class="card">
        <div class="card-header">Edit Produk</div>
        <div class="card-body">
            <form action="" class="">
                <div class="form-group">
                    
                    <input type="hidden" class="form-control" id="idproduk" readonly v-model="produk.idproduk" required>
                </div>
                <div class="form-group">
                    <label for="kode">Kode Produk</label>
                    <input type="text" class="form-control" id="kodeproduk" readonly v-model="produk.kodeproduk" required>
                </div>
                <div class="form-group">
                    <label for="nama">Nama Produk</label>
                    <input type="text" class="form-control" id="nama" v-model="produk.nama" required readonly>
                </div>
                <div class="form-group">
                    <label for="hrg_modal">Harga Modal</label>
                    <input type="text" class="form-control" id="hrg_modal" v-model="produk.hrg_modal" readonly  required>
                </div>  
                <div class="form-group">
                    <label for="hrg_jual">Harga Jual</label>
                    <div class="row">
                        <div class="col-2">
                        <input type="number" step="0.1" class="form-control" id="pj" placeholder="%" v-model="pj" @keyup="pjsen()"> 
                        </div>
                        <div class="col-10">
                            <input type="text" class="form-control" id="hrg_jual" v-model="produk.hrg_jual"  required>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="status">Status</label>
                    <select name="status" id="status" required class="form-control" v-model="produk.status">
                        <option value="Aktif" :selected="produk.status === 'Aktif'">Aktif</option>
                        <option value="Nonaktif" :selected="produk.status === '' || produk.status === undefined ||produk.status === 'Nonaktif'">Nonaktif</option>
                    </select>
                </div>
                
                
                <div class="form-group mt-3">
                    <a @click="updateProduk()" class="btn btn-primary btn-sm"><i class="fas fa-paper-plane"></i> Update</a>
                    <router-link :to="'../../produk/'+produk.kodekat+'/'+this.$route.params.namakat" class="btn btn-dark btn-sm ml-2"><i class="fas fa-caret-left"></i> Kembali</router-link>
                    <div v-if="loadingupd">
                        <i class="fas fa-sync-alt fa-spin"></i> 
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });
export default {
    name: 'produk',
    data(){
        return {
            produk:[],
        }
    },
    mounted(){
        this.siAkun();
        this.ProdukID();
        
    },
    methods: {
        siAkun(){
          const akun = localStorage.getItem("nama");
          const email = localStorage.getItem("akun");
          const level = localStorage.getItem("level");
          this.level=level;
          this.akun=akun;
          this.email=email;
        },
        ProdukID(){    
            const idproduk=this.$route.params.idproduk;
            //console.log(idproduk+this.email)
                axios.get(`https://tokogamingpedia.com/app/reseller_produk?getproduk&rid=${this.email}&cariprod=${idproduk}`)
                .then(res => {
                    console.log(res);
                    this.produk = res.data[0];
                    //console.log(res.data[0]);
                })
                .catch(err => {
                    console.log(err);
                }); 
        },
        formatPrice(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        pjsen() {
            this.produk.hrg_jual = parseInt((this.produk.hrg_modal * this.pj) / 100) + parseInt(this.produk.hrg_modal);
        },
        updateProduk() {
            this.loadingupd=true;
            toaster.info(`Sedang proses`,{
                        position: 'top-right',
                        timeout: 1000
                    });
            const formData = new FormData();
            formData.append('rid', this.email);
            formData.append('idproduk', this.produk.idproduk);
            formData.append('hrg_modal', this.produk.hrg_modal);
            formData.append('hrg_jual', this.produk.hrg_jual);
            formData.append('status', this.produk.status);
            axios.post('https://tokogamingpedia.com/app/reseller_produk?update_produk',formData)
            .then(proresp => {
                //console.log(proresp);
                if(proresp.data.kode==1)
                {
                    toaster.success(`Produk berhasil diperbarui`,{
                        position: 'top-right',
                        timeout: 3000
                    });
                    this.loadingupd=false;
                }
                else
                {
                    toaster.error(`Produk gagal diperbarui. `+proresp,{
                        position: 'top-right',
                        timeout: 3000
                    });
                    this.loadingupd=false;
                }    
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}
</script>