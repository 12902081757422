<template>
    <tbody v-if="loading">
        <tr>
            <td colspan="11" align="center"><i class="fa fa-sync-alt fa-spin fa-2x"></i> Loading...</td>
        </tr>
    </tbody>
    <tbody v-else>    
        <tr>
            <td colspan="11">
                <div class="form-group p-2 col-lg-6 col-md-4">
                    <input
                        type="text"
                        class="form-control"
                        id="cariprod"
                        placeholder="Cari Produk"
                        v-model="cariprod"
                        @keyup="ProdukCategory()"
                    />
                </div>
            </td>
        </tr>
        <tr v-for="(p, index) in this.produk" :key="index">
            <td align="center">{{index+1}}</td>
            <td>
                <router-link :to="'../../editproduk/' + p.idproduk + '/'+p.namakat" class="btn btn-sm btn-info">Edit</router-link>
            </td>
            <td><small>{{p.kodeproduk}} <br>{{p.namakat}}<br> Prv: {{p.provider}}  | Cty: {{ p.country_code }} </small></td>
            <td align="center">{{p.nama}}</td>
            <td align="right" valign="middle" style="font-weight:700"><b>{{formatPrice(p.hrg_modal)}}</b>    
            </td>
            <td align="right" valign="middle" style="font-weight:700"><b>{{formatPrice(p.hrg_jual)}}</b>    
            </td>
            <td align="center" valign="middle" style="font-weight: 800;">{{p.status}}</td>
            <td align="center" valign="middle" style="font-weight: 800;">{{p.sedia}}</td>
            
        </tr>   
    </tbody>
</template>
<script>
import axios from 'axios';
export default {
    name: 'produk',
    data(){
        return {
            produk:[],
            loading: true,
            cariprod:''
        }
    },
    mounted(){
        this.siAkun();
        this.ProdukCategory();
    },
    methods: {
        refresh() {
            // Your logic to refresh or fetch data
            this.ProdukCategory();
        },
        siAkun(){
          const akun = localStorage.getItem("nama");
          const email = localStorage.getItem("akun");
          const level = localStorage.getItem("level");
          this.level=level;
          this.akun=akun;
          this.email=email;
        },
        ProdukCategory(){    
            const codeCategory=this.$route.params.codeCategory;
            if (!codeCategory) {
                axios.get(`https://tokogamingpedia.com/app/reseller_produk?produk&rid=${this.email}&kodekat=&cariprod=${this.cariprod}`)
                .then(res => {
                    this.produk = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
                ;
            }
            else
            {
                axios.get(`https://tokogamingpedia.com/app/reseller_produk?produk&rid=${this.email}&kodekat=${codeCategory}&cariprod=${this.cariprod}`)
                .then(res => {
                    console.log(res);
                    this.produk = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                })
                ;
            }
            
        },
        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        formatPercent(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    }
}
</script>