<template>
    <div class="card">
        <div class="card-header">Edit Produk</div>
        <div class="card-body">
            <form action="" class="">
                <div class="form-group">
                    <label for="kode">Kode Produk</label>
                    <input type="text" class="form-control" id="kodeproduk" readonly v-model="produk.kodeproduk" required>
                </div>
                <div class="form-group">
                    <label for="nama">Nama Produk</label>
                    <input type="text" class="form-control" id="nama" v-model="produk.nama" required>
                </div>
                <div class="form-group">
                    <label for="hrg_modal">Harga Modal</label>
                    <input type="text" class="form-control" id="hrg_modal" v-model="produk.hrg_modal" readonly  required>
                </div>  
                <div class="form-group">
                    <label for="hrg_jual">Harga Jual</label>
                    <div class="row">
                        <div class="col-2">
                        <input type="number" step="0.1" class="form-control" id="pj" placeholder="%" v-model="pj" @keyup="pjsen()"> 
                        </div>
                        <div class="col-10">
                            <input type="text" class="form-control" id="hrg_jual" v-model="produk.hrg_jual"  required>
                        </div>
                    </div>
                    
                </div>
                <div class="form-group">
                    <label for="hrg_reseller1">Harga Reseller Epic</label>
                    <div class="row">
                        <div class="col-2">
                        <input type="number"  step="0.1" class="form-control" id="pr1" placeholder="%" v-model="pr1" @keyup="pjsen()"> 
                        </div>
                        <div class="col-10">
                            <input type="text" class="form-control" id="hrg_reseller1" v-model="produk.hrg_reseller1"  required>
                        </div>
                    </div>
                    
                </div>
                <div class="form-group">
                    <label for="hrg_reseller2">Harga Reseller Legendary</label>
                    <div class="row">
                        <div class="col-2">
                        <input type="number"  step="0.1" class="form-control" id="pr2" placeholder="%" v-model="pr2" @keyup="pjsen()"> 
                        </div>
                        <div class="col-10">
                            <input type="text" class="form-control" id="hrg_reseller2" v-model="produk.hrg_reseller2"  required>
                        </div>
                    </div>
                    
                </div>  
                <div class="form-group">
                    <label for="hrg_reseller3">Harga Reseller Mythic</label>
                    <div class="row">
                        <div class="col-2">
                        <input type="number"  step="0.1" class="form-control" id="pr3" placeholder="%" v-model="pr3" @keyup="pjsen()"> 
                        </div>
                        <div class="col-10">
                            <input type="text" class="form-control" id="hrg_reseller3" v-model="produk.hrg_reseller3"  required>
                        </div>
                    </div>
                    
                </div>
                <div class="form-group">
                    <label for="hrg_reseller4">Harga Reseller Glorious Mythic</label>
                    <div class="row">
                        <div class="col-2">
                        <input type="number"  step="0.1" class="form-control" id="pr4" placeholder="%" v-model="pr4" @keyup="pjsen()"> 
                        </div>
                        <div class="col-10">
                            <input type="text" class="form-control" id="hrg_reseller4" v-model="produk.hrg_reseller4"  required>
                        </div>
                    </div>
                    
                </div>
                <div class="form-group mt-3">
                    <a @click="updateProduk()" class="btn btn-primary btn-sm"><i class="fas fa-paper-plane"></i> Update</a>
                    <router-link :to="'../../produk/'+produk.kodekat+'/'+this.$route.params.namakat" class="btn btn-dark btn-sm ml-2"><i class="fas fa-caret-left"></i> Kembali</router-link>
                    <div v-if="loadingupd">
                        <i class="fas fa-sync-alt fa-spin"></i> 
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });
export default {
    name: 'produk',
    data(){
        return {
            produk:[],
        }
    },
    mounted(){
        this.ProdukID();
    },
    methods: {
        ProdukID(){    
            const idproduk=this.$route.params.idproduk;
                axios.get('https://tokogamingpedia.com/app/?getprodukedit='+idproduk)
                .then(res => {
                    console.log(res);
                    this.produk = res.data[0];
                    //console.log(res.data[0]);
                })
                .catch(err => {
                    console.log(err);
                }); 
        },
        formatPrice(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        pjsen() {
            this.produk.hrg_jual = parseInt((this.produk.hrg_modal * this.pj) / 100) + parseInt(this.produk.hrg_modal);
            this.produk.hrg_reseller1 = parseInt((this.produk.hrg_modal * this.pr1) / 100) + parseInt(this.produk.hrg_modal);
            this.produk.hrg_reseller2 = parseInt((this.produk.hrg_modal * this.pr2) / 100) + parseInt(this.produk.hrg_modal);
            this.produk.hrg_reseller3 = parseInt((this.produk.hrg_modal * this.pr3) / 100) + parseInt(this.produk.hrg_modal);
            this.produk.hrg_reseller4 = parseInt((this.produk.hrg_modal * this.pr4) / 100) + parseInt(this.produk.hrg_modal);
        },
        updateProduk() {
            this.loadingupd=true;
            toaster.info(`Sedang proses`,{
                        position: 'top-right',
                        timeout: 1000
                    });
            const formData = new FormData();
            formData.append('idproduk', this.produk.idproduk);
            formData.append('nama', this.produk.nama);
            formData.append('hrg_modal', this.produk.hrg_modal);
            formData.append('hrg_jual', this.produk.hrg_jual);
            formData.append('hrg_reseller1', this.produk.hrg_reseller1);
            formData.append('hrg_reseller2', this.produk.hrg_reseller2);
            formData.append('hrg_reseller3', this.produk.hrg_reseller3);
            formData.append('hrg_reseller4', this.produk.hrg_reseller4);
            axios.post('https://tokogamingpedia.com/app/?update_produk',formData)
            .then(proresp => {
                console.log(proresp.data);
                if(proresp.data==1)
                {
                    toaster.success(`Produk berhasil diperbarui`,{
                        position: 'top-right',
                        timeout: 3000
                    });
                    this.loadingupd=false;
                }
                else
                {
                    toaster.error(`Produk gagal diperbarui`,{
                        position: 'top-right',
                        timeout: 3000
                    });
                    this.loadingupd=false;
                }    
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}
</script>