import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Master from "../views/master/Index.vue";
import MasterKategori from "../views/master/Kategori.vue";
import Produk from "../views/master/Produk.vue";
import ProdukReseller from "../views/master/ProdukReseller.vue";
import EditProduk from "../views/master/EditProduk.vue";
import EditProdukReseller from "../views/master/EditProdukReseller.vue";
import Transaksi from "../views/transaksi/Index.vue";
import TransaksiDetail from "../views/transaksi/TrkDetail.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/master",
    name: "Master",
    component: Master,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/kategori",
    name: "Kategori",
    component: MasterKategori,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/produk/:codeCategory/:nameCategory",
    name: "Produk",
    component: Produk,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/reseller/produk/:codeCategory/:nameCategory",
    name: "ProdukReseller",
    component: ProdukReseller,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/editproduk/:idproduk/:namakat",
    name: "Edit Produk",
    component: EditProduk,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/reseller/editproduk/:idproduk/:namakat",
    name: "Edit Produk",
    component: EditProdukReseller,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/trkdetail/:tid",
    name: "Detail Order",
    component: TransaksiDetail,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/transaksi",
    name: "Transaksi",
    component: Transaksi,
    meta: { requiresAuth: true }, // Rute yang dilindungi
  },

  // route untuk reseller
  {
    path: "/reseller/dashboard",
    name: "ResellerDashboard",
    component: () => import("../views/Dashboard.Reseller.vue"),
    meta: { requiresAuth: true, role: "reseller" }, // Rute yang dilindungi dan hanya bisa diakses oleh reseller
  },
  {
    path: "/reseller/master",
    name: "ResellerMaster",
    component: () => import("../views/master/Index.Reseller.vue"),
    meta: { requiresAuth: true, role: "reseller" }, // Rute yang dilindungi dan hanya bisa diakses oleh reseller
  },
  {
    path: "/reseller/transaksi",
    name: "ResellerTransaksi",
    component: () => import("../views/transaksi/Index.Reseller.vue"),
    meta: { requiresAuth: true, role: "reseller" }, // Rute yang dilindungi dan hanya bisa diakses oleh reseller
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token'); // Cek token di localStorage
  
  // Jika halaman membutuhkan autentikasi dan token tidak ada, redirect ke login
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ name: 'Signin' }); // Alihkan ke halaman login
  } else {
    next(); // Jika tidak, lanjutkan navigasi
  }
});

export default router;
