<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-10">
                    Detail Order <b>{{ this.$route.params.tid }}</b>
                </div>
                <div class="col-2" align="right">
                    <button class="btn btn-dark btn-sm" @click="$router.go(-1)"><i class="fas fa-caret-left"></i> Kembali</button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <h5>Produk</h5>
                    <p>{{ order.produk }}</p>
                </div>
                <div class="col-sm-6">
                    <h5>Status</h5>
                    <p>{{ order.status }}</p>
                </div>
                <div class="col-sm-6">
                    <h5>Voucher</h5>
                    <p>{{ order.voucher }}</p>
                </div>
                <div class="col-sm-6">
                    <h5>Tanggal</h5>
                    <p>{{ order.created_at }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default
{
    data(){
        return {
            order: [],
        };
    },
    mounted(){
        this.OrderDetail()
    },
    methods: {
        OrderDetail(){
            axios.get('https://tokogamingpedia.com/app/order?order_status&tid='+this.$route.params.tid)
            .then(response => {
                this.order = response.data;
                this.order.status = response.data.data.status;
                this.order.produk = response.data.data.transactions[0].product_name;
                this.order.voucher = response.data.data.transactions[0].voucher_code;

                console.log(this.order);
             })
            .catch(error => {
                console.log(error);
             });
        }
    }
}
</script>