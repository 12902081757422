<template>
    <div class="card">
        <div class="card-header pb-0">
        <h6>Data Transaksi G2G</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div v-if="loading">
                <i class="fas fa-spin fa-sync-alt fa-2x m-3"></i>
            </div>
            <div v-else>
                <div class="row g-1">
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>Tanggal Awal</label>
                        <input
                            type="date"
                            class="form-control"
                            id="fdate"
                            v-model="fdate"
                        />
                    </div>
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>Tanggal Akhir</label>
                        <input
                            type="date"
                            class="form-control"
                            id="ldate"
                            v-model="ldate"
                        />
                    </div>
                    
                    <div class="form-group p-2 col-lg-2 col-md-4">
                        <label>* Klik untuk cari</label>
                        <a @click="TrkData()" class="btn btn-primary w-100"><i class="fas fa-search"></i> Cari</a>
                    </div>  
                </div>
                
                <div class="table-responsive p-1 mt-1">
                    <table class="table align-items-center mb-0 table-striped table-hovered table-condensed table-bordered table-sm">
                        <thead>
                            <tr>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                No
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Aksi
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                            >
                                Order ID
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Tanggal Transaksi
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Status Order
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                Status Pembayaran Order
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                ID Pelanggan
                            </th>
                            <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                            >
                                ID Produk
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Jumlah
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Harga Satuan
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Total Biaya
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Komisi
                            </th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Status Transaksi
                            </th>
                            
                            
                            </tr>
                        </thead>
                        <tbody v-if="loadtrkdata">
                            <tr>
                                <td colspan="11" align="center"><i class="fa fa-sync-alt fa-spin fa-2x"></i> Loading...</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(k, index) in this.trk" :key="index">
                                <td class="text-xs">{{index+1}}</td>
                                <td class="text-center m-0">
                                    <div class="btn-group btn-sm m-0">
                                    <!-- <a v-if="k.status!='SUCCESS'" @click="hapusData(k.tid)" class="btn btn-danger btn-sm px-3"><i class="fas fa-trash"></i></a> -->
                                    <a :href="'/orderdetail/'+k.order_id" class="btn btn-info btn-sm px-3"><i class="fas fa-search"></i></a>
                                    </div>
                                </td>  
                                <td class="text-sm">{{k.order_id}}</td>
                                <td class="text-sm">{{k.tgl_order}}</td>
                                <td class="text-sm">{{k.order_status}}<br>
                                    <a type="button" @click="doTrx(k.order_id)" :class="'btn btn-sm btn-primary p-1'" v-if="k.order_status == 'to_deliver'">Send Manually</a>
                                </td>
                                <td class="text-sm">{{k.order_payment_status}}</td>
                                <td class="text-sm">{{k.buyer_id}}</td>
                                <td class="text-sm">{{k.product_id}}</td>
                                <td class="text-sm">{{k.purchased_qty}}</td>
                                <td class="text-sm" align="right">{{k.offer_currency+' '+formatPrice(k.unit_price)}}</td>
                                <td class="text-sm" align="right">{{k.offer_currency+' '+formatPrice(k.amount)}}</td>
                                <td class="text-sm" align="right">{{k.offer_currency+' '+formatPrice(k.commission_fee)}}<br><small>{{k.commission_fee_rate}}</small></td>
                                <td class="text-sm">{{k.event_type}}</td>                                
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
    </template>
    <script>
    //const apikey_dev='04fe3ca7d9d1255b71f7f4c8b051c232353f1ff41097649543059a61f87878f6';
    import { createToaster } from "@meforma/vue-toaster";
    import axios from 'axios';
    
    const toaster = createToaster({ /* options */ });
    
    export default {
        name: 'kategori',
        data(){
            return {
                loading: true,
                trk:[],
                fdate: new Date().toISOString().substr(0, 10),
                ldate: new Date().toISOString().substr(0, 10),
                
                loadtrkdata: false,
                trkmodal:[],
            }
        },
        mounted(){
            this.getGamesCat();
            this.TrkData();
            this.siAkun()
        },
        methods: {
            siAkun(){
                const akun = localStorage.getItem("akun");
                this.akun=akun;
            },
            getGamesCat(){
                axios.get('https://tokogamingpedia.com/app/?active_category')
                    .then(response => {
                        this.categories = response.data;
                        // toaster.success('Loaded')
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading=false;
                    });
            },
            TrkData(){
                const fdate = this.fdate;
                const ldate = this.ldate;
                //console.log(fdate, ldate)
                this.loadtrkdata=true;
                
                axios.get(`https://tokogamingpedia.com/app/g2g_trx?trxdata&tgl1=${fdate}&tgl2=${ldate}`)
                 .then(response => {
                        this.trk = response.data;
                        //console.log(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loadtrkdata=false;
                    });
            },
            doTrx(order_id){
                //ambil ID dan Server user dulu
                axios.get(`http://tokogamingpedia.com/app/g2g_to_lg?game_id&order_id=${order_id}`)
                .then(response => {
                    console.log(response.data);
                    const userid = response.data.userid;
                    const zoneid = response.data.zoneid;
                    
                    
                    if(userid!=null || zoneid!=null)
                    {
                        toaster.success('Akun game ditemukan',
                        {
                            timeout: 0,
                            showCloseButton: true,
                            progressBar: true,
                            position: 'top-right',
                            tapToDismiss: true,
                            preventDuplicates: true,
                            onHidden: () => {
                                //this.TrkData();
                            }
                        })   
                        axios.get(`https://tokogamingpedia.com/app/g2g_to_lg?product_matching&order_id=${order_id}`)
                        .then(response => {
                            console.log(response.data);
                            const produkkode = response.data.kode;
                            const harga = response.data.harga;
                            const kategori = response.data.kodekat;
                            // const brandid = response.data.brand_id;
                            // const serviceid = response.data.service_id;
                            // const produkg2g= response.data.produk; 
                            if(response.data.message=='Ready'){
                                toaster.success('Menyiapkan Transaksi',
                                {
                                    timeout: 0,
                                    showCloseButton: true,
                                    progressBar: true,
                                    position: 'top-right',
                                    tapToDismiss: true,
                                    preventDuplicates: true,
                                    
                                })

                                const formData = new FormData();
                                formData.append('produk', produkkode);
                                formData.append('usergame', userid);
                                formData.append('server', zoneid);
                                formData.append('harga', harga);
                                formData.append('kategori', kategori);
                                formData.append('reseller',this.akun);

                                console.log(formData);
                                axios.post('https://tokogamingpedia.com/app/order?buat_order', formData)
                                .then(res => {
                                    console.log(res.data);
                                    if(res.data.code=='SUCCESS')
                                    {
                                        this.orderstatus = res.data.code;
                                        this.tid = res.data.data.tid;
                                        toaster.success(res.data.code,{
                                            position: 'top-right',
                                            timeout: 3000,
                                        });
                                        this.ordertid();    
                                        this.pay();
                                    }
                                    else{
                                        toaster.error(res.data.code,{
                                            position: 'top-right',
                                            timeout: 3000
                                        });
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                    toaster.error(err,{
                                        position: 'top-right',
                                        timeout: 3000
                                    });
                                });
                            }
                            else
                            {
                                toaster.error('Permintaan gagal. '+response.data.message,
                                    {
                                        timeout: 0,
                                        showCloseButton: true,
                                        progressBar: true,
                                        position: 'top-right',
                                        tapToDismiss: true,
                                        preventDuplicates: true,
                                        onHidden: () => {
                                            //this.TrkData();
                                        }
                                    }
                                )
                            }
                            //this.TrkData();
                            
                            
                        })
                        .catch(error => {
                            console.error(error)
                        })
                    }
                    else
                    {
                        toaster.error('Akun game tidak ditemukan')
                    }                    
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    
                });
            },
            formatPrice(value) {
                let val = (value/1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            detailTrk(tid){
                axios.get('https://tokogamingpedia.com/app/order?order_status&tid='+tid)
                .then(response => {
                    this.trkmodal.tid=tid;
                    this.trkmodal.code=response.data.code;
                    this.trkmodal.tid=response.data.data.tid;
                    this.trkmodal.produk=response.data.data.transactions[0].product_name;
                    this.trkmodal.voucher=response.data.data.transactions[0].voucher_code;
                    this.trkmodal.status=response.data.data.status;
                    console.log(this.trkmodal);
                })
                .catch(error => {
                    console.error(error)
                })
            }
            
        }
    }
    </script>